import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { PageType } from '@/enums/page-type';
import Page from '../views/site/Page/Page.vue';
import Project from '../views/site/Project/Project.vue';
import Contact from '../views/site/Contact/Contact.vue';
import Login from '../views/admin/Login/Login.vue';
import AdminIndex from '../views/admin/Index/Index.vue';
import store from '../store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Page,
    props: {
      pageType: PageType.HOME
    }
  },
  {
    path: '/admin',
    name: 'Login',
    component: AdminIndex,
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/projects/:projectSlug',
    name: 'Project',
    component: Project,
    props: route => ({ projectSlug: route.params.projectSlug })
  },
  {
    path: '/:pageSlug',
    name: 'Page',
    component: Page,
    props: route => ({ pageType: route.params.pageSlug })
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      isAdmin: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, __from__, next) => {
  if (to.matched.some(record => record.meta.isAdmin)) {
    store.commit('setIsAdmin', { isAdmin: true });
  } else {
    store.commit('setIsAdmin', { isAdmin: false });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.loggedIn) {
      next({
        path: '/admin/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
