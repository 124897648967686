import { Component, Vue } from 'vue-property-decorator';
import { Page } from './models/page';
import { Post } from './models/post';
import AdminLayout from './views/admin/Layout.vue';
import SiteLayout from './views/site/Layout.vue';

@Component({
  components: {
    AdminLayout,
    SiteLayout
  }
})
export default class App extends Vue {
  aboutPage: Page = {};
  homePage: Page = {};
  partnersPage: Page = {};
  projectsPage: Page = {};
  projects: Post[] = [];

  get isAdmin() {
    return this.$store.state.isAdmin;
  }

  async created(): Promise<void> {

    console.log(this.partnersPage);
  }
}
