import Vue, { VueConstructor } from 'vue';
import { Page } from '@/models/page';
import { Post } from '@/models/post';

export interface ContentServiceOptions {
  apiUrl: string
}

export class ContentService {
  static install(Vue: VueConstructor<Vue>, options: ContentServiceOptions): void {
    const contentService = new ContentService(options.apiUrl);

    Vue.prototype.$contentService= contentService;
  }

  constructor(private apiUrl: string) {}

  getAllPages(): Promise<Page[]> {
    return fetch(`${this.apiUrl}/pages`)
      .then(response => response.json())
      .then(response => response.pages);
  }

  getAllProjects(): Promise<any> {
    return fetch(`${this.apiUrl}/posts/tag/projects`)
      .then(response => response.json())
      .then(response => response.posts);
  }

  getPage(slug: string): Promise<Page> {
    return fetch(`${this.apiUrl}/pages/${slug}`)
      .then(response => response.json())
      .then(response => response.page);
  }

  getProject(slug: string): Promise<Post> {
    return fetch(`${this.apiUrl}/posts/${slug}`)
      .then(response => response.json())
      .then(response => response.post);
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $contentService: ContentService
  }
}
