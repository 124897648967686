import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { Post } from '../../../models/post';

@Component
export default class Project extends Vue {
  project: Post = {};
  @Prop(String) projectSlug!: string;

  created() {
    this.getProject();
  }

  @Watch('pageType')
  onProjectTypeChanged() {
    this.getProject();
  }

  private async getProject(): Promise<void> {
    this.project = await this.$contentService.getProject(this.projectSlug);
  }
}
