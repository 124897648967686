import { Component, Vue } from 'vue-property-decorator';
import { Page } from '../../models/page';
import { PageType } from '../../enums/page-type';
import { Post } from '../../models/post';

@Component
export default class SiteLayout extends Vue {
  aboutPage: Page = {};
  homePage: Page = {};
  partnersPage: Page = {};
  projectsPage: Page = {};
  projects: Post[] = [];

  async created() {
    this.aboutPage = await this.$contentService.getPage(PageType.ABOUT);
    this.homePage = await this.$contentService.getPage(PageType.HOME);
    this.partnersPage = await this.$contentService.getPage(PageType.PARTNERS);
    this.projectsPage  = await this.$contentService.getPage(PageType.PROJECTS);
    this.projects  = await this.$contentService.getAllProjects();
  }
}
