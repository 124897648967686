import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Contact extends Vue {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    };
  }

  onSubmit(): void {
    console.log('submit');
  }

  onReset(): void {
    console.log('reset');
  }
}
