import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { ContentService } from './services/content';
import { BootstrapVue } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(ContentService, { apiUrl: process.env.VUE_APP_API_URL });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
