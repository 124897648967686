import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Layout extends Vue {

  get loggedIn() {
    return this.$store.state.loggedIn;
  }

}
