import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Page as PageModel } from '../../../models/page';
import { PageType } from '@/enums/page-type';

@Component
export default class Page extends Vue {
  page: PageModel = {};
  @Prop(String) readonly pageType!: PageType;

  created() {
    this.getPage();
  }

  @Watch('pageType')
  onPageTypeChanged() {
    this.getPage();
  }

  private async getPage(): Promise<void> {
    this.page = await this.$contentService.getPage(this.pageType);
  }
}
