import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class Login extends Vue {
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    };
  }

  onSubmit(): void {
    console.log('submit');
  }
}
